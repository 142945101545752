<template>
    <div class="panel p-md-5" :class="'panel-'+actionInProgress">
        <p class="typo-3" v-html="actionTitle" v-if="actionInProgress !== 'survey'"></p>
        <div class="row gx-3 mb-3 mb-md-0">
            <div class="col-md-6" v-if="bpUpMd">
                <col-summary></col-summary>
            </div>
            <div class="col-md-6 agenda-wrapper"
                 :class="{'d-flex justify-content-center':actionInProgress !== 'create' && !(actionInProgress === 'update' && isUpdatingDone), 'mt-0': actionInProgress === 'survey'}">
                <div class="bg-xs pt-6 pt-md-0 w-100"
                     :class="{'d-flex justify-content-center':actionInProgress !== 'create' && !(actionInProgress === 'update' && isUpdatingDone) && bpUpMd}">
                    <summary-wording></summary-wording>
                    <template v-if="actionInProgress === 'create' || (actionInProgress === 'update' && isUpdatingDone)">
                        <div class="row mb-4">
                            <div class="col-auto agenda-icon-wrapper">
                                <img src="../../../images/google.png" alt="google agenda">
                            </div>
                            <div class="col d-flex align-items-center"><p class="typo-2 mb-0">Google Agenda</p></div>
                            <div class="col-auto">
                                <a class="btn btn-sm btn-outline-primary" :href="googleUrl" target="_blank">Ajouter</a>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-auto agenda-icon-wrapper">
                                <img src="../../../images/outlook.png" alt="google agenda">
                            </div>
                            <div class="col d-flex align-items-center"><p class="typo-2 mb-0">Outlook</p></div>
                            <div class="col-auto">
                                <a class="btn btn-sm btn-outline-primary" :href="icsUrl" target="_blank">Ajouter</a>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-auto agenda-icon-wrapper">
                                <icon name="calendar-outline" class="summary"></icon>
                            </div>
                            <div class="col d-flex align-items-center"><p class="typo-2 mb-0">Autre calendrier</p></div>
                            <div class="col-auto">
                                <a class="btn btn-sm btn-outline-primary" :href="icsUrl" target="_blank">Ajouter</a>
                            </div>
                        </div>
                    </template>
                    <col-summary class="mt-6"
                                 v-if="!bpUpMd && (actionInProgress === 'update' || actionInProgress === 'cancel')"></col-summary>
                    <survey v-if="actionInProgress === 'survey'"></survey>
                </div>
            </div>
        </div>

        <div class="row">
            <legal-links class="order-2 order-md-1"></legal-links>
            <div class="col-md-6 order-1 order-md-2"
                 v-if="actionInProgress !== 'cancel' && actionInProgress !== 'survey'">
                <div class="row">
                    <div class="col" v-if="is_cancellable">
                        <button class="btn btn-tertiary w-100"
                                data-cy="confirm__cancel"
                                :id="!hasCancelFields ? 'gtm-form-cancel' : null"
                                @click.prevent="cancelMeeting()">Annuler
                        </button>
                    </div>
                    <div class="col" v-if="is_updatable && !form.isLockedDate()">
                        <button class="btn w-100"
                                :data-cy="actionInProgress === 'create' ? 'confirm__create' : 'confirm__update'"
                                :class="actionInProgress === 'create' ? 'btn-tertiary' : 'btn-primary'"
                                @click.prevent="updateMeeting()">Modifier
                        </button>
                    </div>
                </div>
            </div>
            <div class="col-md-6 order-1 order-md-2"
                 v-if="actionInProgress === 'survey' && enabled_satisfaction_survey && !successSurvey && ratable">
                <div class="row">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                        <button data-cy="confirm__rate" :disabled="isLoadingSurvey" class="btn btn-primary w-100" @click.prevent="sendSurvey()">Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, toRef } from 'vue';
import calendarLinks from '../../composables/calendar-links';
import form from '../../composables/form';
import steps from '../../composables/steps';
import global from '../../composables/global';
import { useRouter } from 'vue-router';
import popin from '../../composables/popin';
import survey from '../../composables/survey';
import responsive from '../../composables/responsive';

export default {
    setup() {
        const {
            getGoogleUrl: googleUrl,
            getIcsUrl: icsUrl,
        } = calendarLinks;
        const { setActiveStep } = steps;
        const { bpUpMd } = responsive;
        const { state: { payload }, postScheduledMeeting, hasCancelFields } = form;
        const {
            updateActionInProgress,
            state: {
                meeting,
                scheduledMeeting,
                scheduledMeeting: { ratable },
                meeting: { enabled_satisfaction_survey },
            },
            state: stateGlobal,
            actionTitle,
            updateUpdatingDone,
        } = global;
        const { sendSurvey, success: successSurvey, isLoading: isLoadingSurvey } = survey;
        const is_updatable = toRef(payload, 'updatable');
        const is_cancellable = toRef(payload, 'cancellable');
        const actionInProgress = toRef(stateGlobal, 'actionInProgress');
        const isUpdatingDone = toRef(stateGlobal, 'isUpdatingDone');
        const { create: createPopin, present: presentPopin } = popin;
        const router = useRouter();
        const isLoading = ref(false);

        const cancelMeeting = async function () {
            const { hasCancelFields } = form;

            let okConfirm = true;
            if (hasCancelFields.value) {
                updateActionInProgress('cancel');
                router.push({
                    name: 'update-cancel',
                    params: {
                        areaUuid: meeting.area.slug,
                        meetingId: meeting.slug,
                        scheduledMeetingId: scheduledMeeting.id,
                        action: 'cancel',
                    },
                });
                setActiveStep('infos');
                return;
            } else {
                createPopin({
                    header: 'Confirmation',
                    message: 'Êtes-vous sûr de vouloir annuler ce rendez-vous ?',
                    cancelLabel: 'Annuler',
                    confirmLabel: 'Oui',
                    showText: true,
                    requireText: meeting.required_cancellation_reason,
                    textLabel: "Motif d'annulation",
                });
                okConfirm = await presentPopin();
            }
            if (okConfirm) {
                isLoading.value = true;
                postScheduledMeeting('cancel', okConfirm).subscribe(
                    () => {
                        isLoading.value = false;
                        updateActionInProgress('cancel');
                    },
                    () => isLoading.value = false,
                );
            }
        };

        const updateMeeting = function () {
            router.push({
                name: 'update-cancel',
                params: {
                    areaUuid: meeting.area.slug,
                    meetingId: meeting.slug,
                    scheduledMeetingId: scheduledMeeting.id,
                    action: 'update',
                },
            });
            updateActionInProgress('update');
            setActiveStep();
            updateUpdatingDone(false);
        };

        return {
            googleUrl,
            icsUrl,
            is_updatable,
            is_cancellable,
            cancelMeeting,
            updateMeeting,
            actionTitle,
            actionInProgress,
            isUpdatingDone,
            sendSurvey,
            enabled_satisfaction_survey,
            successSurvey,
            bpUpMd,
            hasCancelFields,
            ratable,
            isLoadingSurvey,
            form,
        };
    },
};
</script>

<template>
    <transition name="popin">
        <popin v-if="isOpen" @bgClick="onCancel">
            <p class="typo-1 text-center mb-7" v-if="header" v-html="header"></p>
            <div class="text-center font-4" :class="{
                'mb-5' : showText,
                'mb-7' : !showText
            }" v-if="type === 'alert'">
                <p v-html="message"></p>
            </div>
            <div class="font-4 popin__message" :class="{
                'mb-5' : showText,
                'mb-7' : !showText
            }" v-else v-html="message"></div>
            <div v-if="showText">
                <textarea v-model="textInput" @input="textError = ''" :class="{'is-invalid':textError}" class="form-control mb-5" :placeholder="textLabel"></textarea>
                <p v-html="textError" style="color: var(--bs-form-invalid-color); margin-top: -10px"></p>
            </div>
            <div class="row justify-content-center">
                <div class="col-6" v-if="cancelLabel">
                    <button class="btn btn-tertiary w-100" @click="onCancel">{{
                            cancelLabel
                        }}
                    </button>
                </div>
                <div class="col-6" v-if="confirmLabel">
                    <button class="btn btn-primary w-100" @click="onConfirm">{{
                            confirmLabel
                        }}
                    </button>
                </div>
            </div>
        </popin>
    </transition>
</template>

<script>
import popin from '../../composables/popin';

export default {
    setup() {
        const {
            isOpen,
            type,
            header,
            message,
            cancelLabel,
            confirmLabel,
            showText,
            requireText,
            textLabel,
            onCancel,
            onConfirm,
            textInput,
            textError,
        } = popin;

        return {
            isOpen,
            type,
            header,
            message,
            cancelLabel,
            confirmLabel,
            onCancel,
            onConfirm,
            showText,
            requireText,
            textLabel,
            textInput,
            textError,
        };
    },
};
</script>

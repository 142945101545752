import { ref } from 'vue';

const isOpen = ref(false);
const gdprIsOpen = ref(false);
const gdprFormIsOpen = ref(false);
const summaryIsOpen = ref(false);
const type = ref(''); // alert, popin (rich wysiwyg content)
const header = ref('');
const message = ref('');
const cancelLabel = ref('');
const confirmLabel = ref('');
const showText = ref(false);
const requireText = ref(false);
const textLabel = ref('');
const textInput = ref('');
const textError = ref('');
let resolvePromise = null;

function create({
                    type: typeInput = 'alert',
                    header: headerInput,
                    message: messageInput,
                    cancelLabel: cancelLabelInput,
                    confirmLabel: confirmLabelInput,
                    showText: showTextInput = false,
                    requireText: requireTextInput = false,
                    textLabel: textLabelInput,
                }) {
    type.value = typeInput;
    header.value = headerInput;
    message.value = messageInput;
    cancelLabel.value = cancelLabelInput;
    confirmLabel.value = confirmLabelInput;
    showText.value = showTextInput;
    requireText.value = requireTextInput;
    textLabel.value = textLabelInput;
}

function present() {
    isOpen.value = true;
    return new Promise((resolve) => {
        resolvePromise = resolve;
    });
}

function onCancel() {
    isOpen.value = false;
    resolvePromise(false);
    textInput.value = '';
    textError.value = '';
}

function onConfirm() {
    if (showText.value && requireText.value && textInput.value.length === 0) {
        textError.value = 'Champ obligatoire';
        return;
    }
    isOpen.value = false;
    resolvePromise((showText.value && textInput.value.length) > 0 ? textInput.value : true);
    textInput.value = '';
    textError.value = '';
}

function showError(data) {
    create({
        header: data.header ?? 'Alerte',
        message: data.customErrorMsg,
        confirmLabel: 'OK',
    });

    present();
}

export default {
    isOpen,
    type,
    header,
    message,
    confirmLabel,
    cancelLabel,
    create,
    present,
    onCancel,
    onConfirm,
    showError,
    gdprIsOpen,
    gdprFormIsOpen,
    summaryIsOpen,
    showText,
    requireText,
    textLabel,
    textInput,
    textError,
};
